// src/collections.js
const Collections = {
  USERS: "users",
  INVENTORY_CATEGORY: "inventoryCategory",
  INVENTORY_ITEMS:'inventoryItems',
  INVOICES: "invoices",
  // Add more collections as needed
};

export default Collections;
